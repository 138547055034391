header {

    #titlebar {
        font-size: 2rem;
        padding: 15px 0;
        font-family: "New Athletic M54", serif;
        background-color: #fff;
        min-height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        &_inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .login {
            text-transform: uppercase;
            font-size: 1.5rem;
            background-color: $yellow;
            display: inline-flex;
            text-align: center;
            vertical-align: center;
            justify-content: center;
            flex-direction: column;
            height: 60px;
            width: 140px;
            line-height: 1.2;
            color: $black;
            font-family: Montserrat, serif;
            @extend .shadow-box;
        }

        .title {
            margin-left: 50px;
            font-size: 2.6rem;
            letter-spacing: 3px;
        }

        .container {
            padding-left:0;
        }
    }

    #menu {
        background-color: $red;
        nav {
            text-align: right;
            display: flex;
            justify-content: center;
            padding: 1rem 0;
            color: $white;

            .links-wrapper {
                width: 100%;
                max-width: 1024px;
            }

            a {
                font-size: 1.3rem;
                display: inline-block;
                padding: 3px 0.8rem;
                text-transform: uppercase;
                font-weight: 800;
                border-right: 1px solid #fff;
                &:last-child {
                    border-right: none;
                    padding-right: 0;
                }
            }
        }
    }

}
