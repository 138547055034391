body.register {
	main {
        background-image: url(/build/images/fond_superman.jpg);
		background-position-y: 80%;
        background-repeat: no-repeat;
        background-color: black;

        .main-title {
            font-weight: bold;
        }
    }
}
body.login {
    main {
        .main-title {
            font-weight: bold;
        }
    }
}
