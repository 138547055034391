.pages {
    &.establishments {
        .main-title {
            font-weight: bold;
        }
        main {
            //background-image: url(/build/images/fond_principal.jpg);
        }
    }

    .main-title {
        color: white;
    }
    .bg-white {
        padding: 15px 25px 25px;
        margin-bottom: 30px;

    }
    .link {
        &.bold {
            text-decoration: underline;
            font-weight: 700;
        }
    }
    em {
        font-size: 1rem;
        margin-bottom: 15px;
    }

    ul {
        margin: 0 0 1.5em;
        padding: 0;

        h2 {
            font-family: Montserrat, sans-serif;
        }


        & > li {
            margin: 15px;
            padding: 0;
            list-style-type: none;

            div {
                padding-left: 2em;
            }
        }
    }
}
