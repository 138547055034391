@font-face {
    font-family: "Bauhaus";
    src: url('../fonts/agefos/Bauhaus_.woff');

}@font-face {
    font-family: "big_noodle_titling";
    src: url('../fonts/agefos/big_noodle_titling.ttf');
}

@font-face {
    font-family: "MinionPro-Regular";
    src: url('../fonts/agefos/MinionPro-Regular.otf');
}
@font-face {
    font-family: "New Athletic M54";
    src: url('../fonts/agefos/New Athletic M54.ttf');
}
@font-face {
    font-family: "ZapfDingbats";
    src: url('../fonts/agefos/ZapfDingbats.ttf');
}


@font-face {
    font-family: "Montserrat";
    src: url('../fonts/agefos/Montserrat-Regular.otf');
}
@font-face {
    font-family: "Montserrat";
    src: url('../fonts/agefos/Montserrat-SemiBold.otf');
    font-weight: 600;
}

@font-face {
    font-family: "Montserrat";
    src: url('../fonts/agefos/Montserrat-Bold.otf');
    font-weight: bold;
}
@font-face {
    font-family: "Montserrat";
    src: url('../fonts/agefos/Montserrat-Italic.otf');
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url('../fonts/agefos/Montserrat-Medium.otf');
    font-weight: 500;
}
@font-face {
    font-family: "Montserrat";
    src: url('../fonts/agefos/Montserrat-MediumItalic.otf');
    font-weight: 500;
    font-style: italic;
}

body{
    font-family: Montserrat, sans-serif;
}

//h1,h2,h3,h4,h5{
//    font-family: 'big_noodle_titling', serif;
//    font-weight: 700;
//}

