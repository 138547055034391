@import "common/variables";

/** Import everything from autoload */
//@import "./autoload/*.scss";
@import "./autoload/select2.min.css";

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

/** Import theme styles */
@import "common/fonts";
@import "common/shortcodes";
@import "common/global";
@import "layouts/footer";
@import "layouts/header";
@import "layouts/home";
@import "layouts/legals";
@import "layouts/modal";
@import "layouts/pages";
@import "layouts/register";
