$white: #ffffff;
$black: #000000;
$green: #bdcc27;
$gold: #B59A5E;
$yellow: #F3CA16;
$red: #ED1C26;
$lightblue: #63bfb5;
$blue : #293F8D;


$blue: #005C9B !default;
$second : $gold;
$lightblue : #00B7ED !default;
$primary : $lightblue;

$link-color:inherit;
$link-hover-color: $gold;


// Box backgorund-colors;

$box-gold : $gold;

$colors: (
    green: $green
);

$theme-colors: (
    "primary": $second
);

/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 67.5vw
);

$border-radius : 0.8rem;
//$btn-border-radius : 1rem;
$btn-line-height : 1.1;

$modal-content-border-radius : 1.3rem;
