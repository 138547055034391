.modal-dialog {
    .modal-header {
        .modal-title {
            font-family: Montserrat, sans-serif;
            text-transform: uppercase;
        }
    }

    .modal-body {
        a {
            font-size: 1rem;
        }
    }
  form {
    position: relative;
    width: 100%;
    margin: auto;
    max-width: 260px;
    //padding-top: 40px;
  }

  label {
    display: none;
  }

  select, input {
    border: 1px solid $gold;
    border-radius: 0;
  }

  button {
    //left: 0;
    //width: 100%;
    //position: absolute;
    //top: 4px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.3rem;
  }
}
