footer {
    padding: 25px 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    nav {
        display: flex;
        flex-direction: column;
        align-items: start;
        a {
            color: $black;
        }
    }
    .credits {
        margin-top : 70px;
    }
    .logos {
        img{
            margin: 0 7px;
            max-width: 240px;
            max-height: 41px;
        }
        p {
            margin-top: 15px;
        }
    }
    .footer-pog {
        font-family: 'Bauhaus';
        font-size: 1.2em;
        color: $black;
        span {
            color: #ed5c9c;
        }
    }
}
