html {
    font-size: 62.5%;
}

body{
    background-color: $gold;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Montserrat, sans-serif;
    > main {
        flex: 1;
    }
}

a{
    &:hover{
        text-decoration: none;
    }

    &.bolder {
        text-decoration: underline;
        font-weight: bold;
    }
}

.shadow-box {
    box-shadow: 3px 3px 2px rgba(#000, .3);
}
.shadow-text {
    text-shadow: 3px 3px 2px rgba(#000, .3);
}

.btn-primary {
    color: white;
}

.block {
    display: flex;
    flex-direction: column;
    padding: 3rem 5rem;
    //width: 100%;
    border-radius: $border-radius;
    text-align: justify;
    //font-size: 1.3rem;
    line-height: 1.2;
    background-color: $gold;

    .icon-block {
        max-width: 85px;
        margin-bottom: 1rem;
    }

    .link {
        font-weight: 600;
        text-decoration: underline;
        align-items: flex-end;
        margin-left: auto;
    }

    .title {
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;
        font-size: 1.8rem;
        display: inline-block;
        text-align: center;
        width: 100%;
        font-weight: normal;
        color: white;
        margin-bottom: 25px;

        strong {
            display: block;
            font-size: 1.8em;
            line-height: 1.3;
        }
    }

    > ul {
        padding: 0;
        li {
            list-style: none;
            position: relative;
            margin-bottom: 15px;
            margin-left: 15px;
            color: $black;
            .subtitle {
                margin-left: -15px;
                display: flex;
                align-items: center;
                color: white;
                font-size: 1.1em;
                &::before {
                    content: "●";
                    display: inline-block;
                    font-size: larger;
                    color: white;
                    margin-right: 2px;
                    margin-top: -3px;
                    font-size: 1.4em;
                }
            }
            ul {
                margin-left: -15px;
            }
        }

        ul {
            padding: 0;
            > li {
                padding-left: 1.5rem;
                margin-bottom: 0;
                &::before {
                    position: absolute;
                    left: 0;
                    content: "-";
                    color: black;
                }
            }
        }

    }
}

.yellow-text {
    color: $yellow;
}

.bg-gold {
    background-color: $box-gold;
}

.bg-cyan {
    background-color: $lightblue;
}

.bg-blue {
    background-color: $blue;
    color: $white;
    > ul {
        li {
            color: $white !important;
            li {
                &::before {
                    color: $white;
                }
            }
        }
    }
}

.bg-white {
    background-color: $white;

    strong, .title {
        color: $gold !important;
    }

    > ul {
        li {
            .subtitle::before {
                color: $gold;
            }
        }
    }
}

.main-title {
    display: block;
    margin: 2.5rem 0;
    font-size: 4rem;
    font-weight: normal;
    text-transform: uppercase;
    strong {
        font-weight: bold;
        color: black;
    }
}

.login,
.register {
    .main-title {
        color: white;
    }
    .bg-white {
        padding: 15px 25px 25px;
        margin-bottom: 2.5rem;
    }
}
