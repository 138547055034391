body.home {
    background-color: $black;
    main {
        background-image: url('/build/images/fond.jpg');
        background-size: cover;
        background-position: bottom center;
        position: relative;
        &:before {
            background-image: url('/build/images/wonder-women.png');
            background-size: cover;
            background-position: bottom center;
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
        }
        &:after {
            background-image: url('/build/images/hulk.png');
            background-size: cover;
            background-position: bottom center;
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
        }
    }

    .dates {
        text-align: center;
        margin: 3rem auto 2.5rem auto;
        .title {
            font-size: 4.2rem;
            font-family: Montserrat, sans-serif;
            text-transform: uppercase;
            display: inline-flex;
            align-items: center;
            position: relative;
            color: $white;
            font-weight: normal;
            margin: 0;
            &:before,
            &:after {
                content: "\2605";
                display: inline-block;
                padding: 0 .2rem;
                color: $yellow;
                font-size: 1.5em;
            }
            span {
                font-weight: bold;
                padding: 0 1rem;
            }
        }
    }
}



em {
    display: block;
    font-size: 0.5em;

    &.big {
        font-size: 10px;
        margin-top: 10px;
    }
}


.account {
    display: flex;
    margin: 5rem 0;
    background-color: #fff;
    border-radius: $border-radius;
    flex-wrap: nowrap;
    text-align: center;
    flex-direction: column;
    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    > div {
        padding: 15px;
    }

    .account_cta {
        font-size: 2.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        padding: 15px 5%;

        > strong {
            display: block;
            font-size: 2.5rem;
            text-transform: uppercase;
            width: 100%;
        }

        flex-basis: 66%;

        > button {
            font-size: 2rem;
            width: 48%;
            color: white;
            padding-top: 10px;
            padding-bottom: 10px;

            strong {
                text-transform: uppercase;
            }
        }
    }

    .description {
        flex-basis: 33%;
        font-weight: 500;
        font-size: 1.7rem;

        p {
            margin: 0 auto;
        }
    }
}

.top {
    justify-content: space-between;
}

.calendar {
    padding-top: 2rem;
    justify-content: space-evenly;
    background-color: #fff;
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .title {
        font-size: 2.4rem;
        text-transform: uppercase;
        color: $gold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        margin-bottom: 20px;

        strong {
            color: $red;
            font-size: 1.2em;
        }
    }

    .date {
        background-color: $red;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        color: white;
        text-align: right;
        width: 40%;
        padding: 8px;
        vertical-align: center;
        justify-content: flex-end;
        position: relative;
        font-weight: bold;
        span {
            font-weight: bold;
        }

        &:before {
            content: "★";
            position: absolute;
            left: -1.7rem;
            transform: rotate(-20deg);
            color: $gold;
            font-size: 5rem;
            @extend .shadow-text
        }
    }

    .step {
        font-size: 1.2rem;
        width: 40%;
        height: 100%;
    }

    > div:not(:first-child) {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        flex-direction: row;
        align-items: start;
        padding: 20px;
        position: relative;

        &:not(:last-child) {
            &:after {

                position: absolute;
                left: 15%;
                content: " ";
                width: 70%;
                border-bottom: 1px solid #000;
                bottom: 0;
            }
        }

        > div {
            display: flex;
            align-items: center;
        }
    }
}

.informations {
    justify-content: space-between;
    [class*="col-"]{
        margin-bottom: 30px;
        .block {
            height: 100%;
        }
    }

    a {
        transition: 0.4s color;

        &.white {
            &:hover {
                color: white;
            }
        }
    }

}
