.legals {
    &.faq {
        .main-title {
            font-weight: bold;
        }
        main {
            //background-image: url(/build/images/fond_principal.jpg);
        }
    }
    &.cookies, &.privacy{
        .main-title {
            color : $black;
            strong {
                color : $white;
            }
        }
    }

    &.regulations {
        strong {
            color : $black !important;
        }
        .contact-box-wrapper {
            display: flex;
            justify-content: center;
            .contact-box {
                border: 1px solid black;
                width: fit-content;
                padding: 5px 30px;
            }
        }
    }

    ul.big-disc > li {
        list-style-type: none;
        &:before {
            content: "●";
            margin-right: 5px;
        }
    }
    ul.check > li {
        list-style-type: none;
        &:before {
            content: "✔";
            margin-right: 5px;
        }
    }
    ul.arrow > li {
        list-style-type: none;
        &:before {
            content: "⮚";
            margin-right: 5px;
        }
    }
    ul.dash > li {
        list-style-type: none;
        &:before {
            content: "-";
            margin-right: 5px;
        }
    }
    ul.normal > li {
        list-style-type: none;
        &:before {
            content: "•";
            margin-right: 5px;
        }
    }
    ul.none > li {
        list-style-type: none;
        text-indent: 0;
    }

    &.cookies {
        main {
            .container {
                .row {
                    font-style: italic;
                }
            }
        }

        h2 {
            font-style: normal;
        }
        .sub-menu {
            font-weight: 700;
            text-decoration: underline;
            font-style: normal;
        }
        .browser-list {
            padding-left: 15px;
            text-decoration: underline;
        }
    }

    .main-title {
        color: white;
    }
    .bg-white {
        padding: 15px 25px 25px;
        margin-bottom: 30px;

    }
    .link {
        &.bold {
            text-decoration: underline;
            font-weight: 700;
        }
    }
    em {
        font-size: 1rem;
        margin-bottom: 15px;
    }

    ol {
        margin: 0 0 1.5em;
        padding: 0;
        counter-reset: item;

        h2 {
            font-family: Montserrat;
            display: block;
            margin-left: 3rem;
            &.question-title-faq{
                font-size: 1.7rem;
            }
        }


        & > li {
            margin: 0;
            padding: 0 0 0 0em;
            list-style-type: none;
            counter-increment: item;

            margin-top: 35px;
            margin-bottom: 15px;



            div {
                padding-left: 3rem;
                ul{
                    padding-inline-start: 0;
                }
            }
            &:before {
                content: counter(item) ".";
                position: absolute;
                display: inline-block;
                //width: 1em;
                //padding-right: 0.5em;
                font-weight: bold;
                text-align: right;
                //margin-left: 10px;
                //margin-top: -3px;
                font-size: 2rem;
                line-height: 1;
            }
        }
    }
}
